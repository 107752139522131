import React, { useContext, useMemo } from 'react'
import { Translations } from '../services/TranslationService'
import { StateContext } from '../services/StateService'
import DataTable from '@3mcom/mds-library/dist/node/DataTable'
import { trackPDPData } from '../services/TealiumService'

const ProductMiniTable = ({ data, handleChangeOptions }) => {
    const  { isParent } = useContext(StateContext)
    const translations = useContext(Translations)
    const columns = useMemo(
        () => [
            {
                id: 'column1',
                accessor: 'column1',
                Header: '',
                style: {
                    width: '50%'
                }
            },
            {
                id: 'column2',
                accessor: 'column2',
                Header: '',
                style: {
                    color: 'black',
                    width: '50%'
                }
            }
        ],
        []
    )
    const getData = () => {
        return data.map((attribute) => {
            return {
                column1: [attribute.label],
                column2: attribute?.values?.join(', ') || ''
            }
        })
    }

    if (!data || data?.length === 0) return null
    return (
        <div className={`sps2-pdp_pSelector--miniTable ${!isParent ? 'sps2-pdp_pSelector--miniTable_centered' : ''}`}>
            <button
                className={`mds-link_primary sps2-pdp_pSelector--miniTable_change ${isParent ? 'sps2-pdp_pSelector--miniTable_change--right' : 'sps2-pdp_pSelector--miniTable_change--left'}`}
                onClick={() => {
                    handleChangeOptions()
                    trackPDPData(
                        `PDP Buying Zone Mini Table Button Click: ${translations.changeOptions}`,
                        'Engagement Event'
                    )
                }}
            >
                {translations.changeOptions}
            </button>
            <DataTable.Table
                columns={columns}
                data={getData()}
                disableHeader={true}
                percentageBase={true}
            ></DataTable.Table>
        </div>
    )
}

export default ProductMiniTable