import React, { useContext } from 'react'
import { Translations } from '../../services/TranslationService'
import { StateContext } from '../../services/StateService'
import SelectorsContainer from '../../components/SelectorsContainer'
import ProductMiniTable from '../../components/ProductMiniTable'
import '../../css/ProductSelector.scss'

const ProductSelector = ({
    prodOptButtonRef,
    handleProductSelectorRequest,
    displaySelectors,
    setDisplaySelectors
}) => {
    const { isParent, moreOptions, dispatch, selectedOptions, focusViewProductOptions } =
        useContext(StateContext)
    const translations = useContext(Translations)

    /* istanbul ignore next */
    const handleReset = () => {
        dispatch({
            type: 'pdp_update_UI_options',
            payload: {
                focusViewProductOptions:
                    focusViewProductOptions && !focusViewProductOptions
            }
        })
        dispatch({
            type: 'pdp_set_selected_options',
            payload: { initialState: true }
        })
        dispatch({
            type: 'pdp_set_product_options',
            payload: {
                productOptions: [],
                moreOptions: { totalSelected: 0 },
                endpoint: ''
            }
        })
        dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: false })
        dispatch({
            type: 'set_AJAX_errors',
            payload: {
                productOptionsAjaxError: false,
                selectorsAjaxError: false
            }
        })
    }

    const getNumberOfOptions = (displayLink = true) => {
        let numberOfOptions
        if (selectedOptions?.totalSelected === moreOptions?.totalSelected) {
            numberOfOptions = `${moreOptions?.totalSelected} ${translations.productOptions}`
        } else {
            numberOfOptions = (
                <span>
                    <span className={`${displayLink ? 'mds-link_primary' : ''}`}>
                        {`${selectedOptions?.totalSelected} ${translations.matching}`}
                    </span>
                    {` ${translations.of} ${moreOptions?.totalSelected} ${translations.productOptions}`}
                </span>
            )
        }
        return numberOfOptions
    }

    const getMiniTableAttributes = () => {
        return moreOptions?.classificationAttributes.filter((attribute) => {
            const attributeFound = moreOptions.options.find(
                (selector) => selector.identifier === attribute.identifier
            )
            if (attributeFound) return attribute
        })
    }

    const clearAllOption = () => {
        let clearAllFlag = false
        Object.keys(selectedOptions?.options)?.map((key) => {
            const selector = selectedOptions.options[key]
            if (selector?.values?.length > 1) {
                clearAllFlag = true
            }
            return clearAllFlag
        })
        return clearAllFlag
    }

    const componentsToRender = []
    if (
        !isParent &&
        moreOptions?.displayTable === true &&
        moreOptions?.classificationAttributes?.length > 0 &&
        !displaySelectors
    ) {
        componentsToRender.push(
            <ProductMiniTable
                key="miniTable_x"
                data={getMiniTableAttributes()}
                handleChangeOptions={() => setDisplaySelectors(true)}
            />
        )
    } else if (selectedOptions?.totalSelectors > 0) {
        componentsToRender.push(
            <SelectorsContainer
                key="pSelector_x"
                handleReset={handleReset}
                prodOptButtonRef={prodOptButtonRef}
                handleProductSelectorRequest={handleProductSelectorRequest}
                getNumberOfOptions={getNumberOfOptions}
                clearAll={clearAllOption()}
            />
        )
    }

    return (
        <div className="sps2-pdp_pSelector--container">
            {componentsToRender.map((component) => component)}
        </div>
    )
}

export default ProductSelector