import React, { useRef, useContext, useEffect } from 'react'
import { Translations } from '../../services/TranslationService.jsx'
import { StateContext } from '../../services/StateService.jsx'
import { useIsDesktop, scrollToSection } from '../../services/UtilityService'
import { selectorsArrayToObj } from '../../services/PDPService.jsx'
import { getRequest } from '../../services/AxiosService'
import MediaGallery from './MediaGallery.jsx'
import PurchaseOptions from './PurchaseOptions.jsx'
import ProductOptions from './ProductOptions.jsx'
import DescriptionZone from './DescriptionZone'
import DisclaimerLinks from '../../components/DisclaimerLinks.jsx'
import Alert from '@3mcom/mds-library/dist/node/Alert'
import '../../css/BuyingZone.scss'

const BuyingZone = ({
    buyingZoneRef,
    supportRef,
    supportSecondaryRef,
    supportTertiaryRef,
    disclaimerInformationRef,
    resourcesRef,
    detailsRef,
    showDetails
}) => {
    const translations = useContext(Translations)
    const productOptionsRef = useRef(null)
    const prodOptButtonRef = useRef(null)
    const isDesktop = useIsDesktop()
    const {
        moreOptions,
        dispatch,
        endpoints,
        productOptions,
        loadingProductOptions,
        pdpPageOffset,
        selectedOptions,
        errors
    } = useContext(StateContext)

    const isTableRendered = productOptions?.length > 0

    useEffect(() => {
        if (moreOptions?.options?.length > 0) {
            dispatch({
                type: 'pdp_set_selected_options',
                payload: { initialState: true }
            })
        }
    }, [])

    /** Updates the selectors and table data */
    useEffect(() => {
        if (selectedOptions?.hasChanged) {
            handleProductSelectorRequest(isTableRendered)
        }
    }, [selectedOptions])

    const updateSelectedOptions = (data) => {
        const newOptions = data.moreOptions.options
        const newTotal = data.moreOptions.totalSelected
        const newOptionsObj = selectorsArrayToObj(newOptions)
        const { lastSelected, totalSelectors, options: oldOptions } = selectedOptions // remove lastSelected, hasChanged, totalSelected from old selectors

        const oldNewCombined = Object.keys(oldOptions).map((key) => {
            const foundOption = newOptionsObj[key] // found option will be a Selector with new values []
            let finalValues = []
            const disabled = oldOptions[key].selectedValue === '' // keep the selected elements available
            let newSelected = ''
            if (foundOption) {
                finalValues = oldOptions[key]?.values?.map((oldValue) => {
                    const matchValue = foundOption.values.find(
                        (value) =>
                            value.value + value.uom === oldValue.value + oldValue.uom
                    )
                    if (matchValue?.selected) {
                        newSelected = matchValue?.api
                    }

                    return matchValue || { ...oldValue, disabled: true } // disable options if they are not present
                })

                return {
                    ...oldOptions[key],
                    values: finalValues,
                    disabled: false,
                    selectedValue: newSelected
                }
            } else {
                return {
                    ...oldOptions[key],
                    values: oldOptions[key]?.values?.map((oldValue) => ({
                        ...oldValue
                    })),
                    disabled: disabled
                }
            }
        })

        dispatch({
            type: 'pdp_set_selected_options',
            payload: {
                selectedOptions: {
                    options: selectorsArrayToObj(oldNewCombined),
                    totalSelected: newTotal,
                    totalSelectors: totalSelectors,
                    lastSelected: lastSelected,
                    hasChanged: false,
                    wtbMmmIds: data?.moreOptions?.wtbMmmIds
                }
            }
        })
    }

    const updateTableData = (data, endpoint, scrollToTable) => {
        dispatch({
            type: 'pdp_set_product_options',
            payload: { ...data, endpoint }
        })
        if (scrollToTable) {
            productOptionsRef?.current?.focus()
            scrollToSection(productOptionsRef, pdpPageOffset)
        }
        dispatch({
            type: 'pdp_set_loading_product_options',
            payload: {
                loading: false,
                isUpdate: isTableRendered
            }
        })
    }

    const handleProductSelectorRequest = (isGetProductOptions, scrollToTable = false) => {
        /* Resets the errors before a new request */
        dispatch({
            type: 'set_AJAX_errors',
            payload: {
                productOptionsAjaxError: false,
                selectorsAjaxError: false
            }
        })
        /* Determines the correct endpoint based on the need for product options */
        const endpoint = getEndpoint(isGetProductOptions)
        if (isGetProductOptions) {
            dispatch({
                type: 'pdp_update_UI_options',
                payload: {
                    focusViewProductOptions: false,
                    loadingProductOptions: {
                        loading: true,
                        isUpdate: isTableRendered
                    }
                }
            })
        }
        getRequest(endpoint)
            .then((response) => {
                if (response.data) {
                    updateSelectedOptions(response.data)
                    if (isGetProductOptions && response?.data?.productOptions) {
                        updateTableData(response.data, endpoint, scrollToTable)
                    }
                }
            })
            .catch((error) => {
                const payload = isGetProductOptions
                    ? { selectorsAjaxError: false, productOptionsAjaxError: true }
                    : { selectorsAjaxError: true, productOptionsAjaxError: false }

                dispatch({ type: 'set_AJAX_errors', payload })

                dispatch({
                    type: 'pdp_set_loading_product_options',
                    payload: {
                        ...loadingProductOptions,
                        loading: false
                    }
                })

                dispatch({
                    type: 'show_error_message',
                    payload: `Error ${
                        isGetProductOptions
                            ? 'loading product options'
                            : 'loading selectors data'
                    }: ${error}`
                })
            })
    }

    const getEndpoint = (isGetProductOptions) => {
        let endpoint
        if (isGetProductOptions) {
            if (selectedOptions?.lastSelected?.value) {
                endpoint = `${selectedOptions.lastSelected.value}&size=${
                    moreOptions?.totalSelected
                }${
                    selectedOptions.lastSelected.value.includes('showProducts')
                        ? ''
                        : '&showProducts=true'
                }`
            } else {
                endpoint = `${endpoints.productOptions}?baseId=${moreOptions.baseId}&terminalCategory=${moreOptions.terminalCategory}&showProducts=true&size=${moreOptions?.totalSelected}`
            }
        } else {
            endpoint = `${selectedOptions?.lastSelected?.value}${
                selectedOptions.lastSelected?.value.includes('returnMmmids')
                    ? ''
                    : `&returnMmmids=${!moreOptions?.displayTable}`
            }`
        }
        return endpoint
    }

    return (
        <div className="sps2-pdp_buying">
            <div ref={buyingZoneRef} className="sps2-pdp_buying--container">
                <MediaGallery />
                <DescriptionZone
                    disclaimerInformationRef={disclaimerInformationRef}
                    resourcesRef={resourcesRef}
                    detailsRef={detailsRef}
                    showDetails={showDetails}
                    prodOptButtonRef={prodOptButtonRef}
                    handleProductSelectorRequest={handleProductSelectorRequest}
                />
                <PurchaseOptions
                    supportRef={supportRef}
                    supportSecondaryRef={supportSecondaryRef}
                    supportTertiaryRef={supportTertiaryRef}
                    productOptionsRef={productOptionsRef}
                    prodOptButtonRef={prodOptButtonRef}
                    getProductEndpoint={getEndpoint}
                    handleProductSelectorRequest={handleProductSelectorRequest}
                />
            </div>
            {!isDesktop && (
                <DisclaimerLinks disclaimerInformationRef={disclaimerInformationRef} />
            )}
            {(loadingProductOptions?.loading || productOptions?.length > 0) &&
                moreOptions?.displayTable && (
                    <ProductOptions
                        productOptionsRef={productOptionsRef}
                        buyingZoneRef={buyingZoneRef}
                    />
                )}
            {errors?.productOptionsAjaxError && (
                <div className="sps2-pdp_ajaxError--centered mds-margin_small--bottom mds-margin_medium--top">
                    <Alert.InContext variant="error">
                        {translations.ajaxError}
                    </Alert.InContext>
                </div>
            )}
        </div>
    )
}

export default BuyingZone